import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'

const NewsletterModal = () => {

    const [modalShow, setModalShow] = useState(false);
    const [matches, setMatches] = useState(window.matchMedia("(min-width: 475px)").matches)


    useEffect(() => {
        window.matchMedia("(min-width: 475px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, [])

    // Función para cerrar el modal y registrar que ya se mostró
    const handleClose = () => {
        setModalShow(false);
        // Guarda la fecha actual en localStorage cuando el modal se cierra
        localStorage.setItem('lastModalShowDate', new Date().toDateString());
    };

    useEffect(() => {
        // Verifica si el modal ya se mostró hoy
        const lastModalShowDate = localStorage.getItem('lastModalShowDate');
        const today = new Date().toDateString();

        if (lastModalShowDate !== today) {
            // Si no se mostró hoy, establece un timeout para mostrar el modal después de 10 segundos
            const timer = setTimeout(() => {
                setModalShow(true);
            }, 15000);

            // Limpia el timeout en caso de que el componente se desmonte
            return () => clearTimeout(timer);
        }
    }, []);

    return (
        // <Modal show={modalShow} onHide={handleClose} id="modalnewsletter"> 
        //   <Modal.Header closeButton></Modal.Header>
        //   <Modal.Body style={{backgroundImage: matches ? "url('images/newsletter_bkg.png')" : "url('images/newsletter_bkg_mobile.png')" }}>
        //     <a href='https://optin.myperfit.com/subscribe/entelequia/Ki1VK9NH' className="btn" target='_blank' rel="noreferrer">
        //       Registrate acá
        //     </a>
        //   </Modal.Body>
        // </Modal>
        <Modal show={modalShow} onHide={handleClose} id="modalnewsletter">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{ backgroundImage: matches ? "url('images/newsletter_bkg.png')" : "url('images/newsletter_bkg_mobile.png')" }}>
                <a href='https://optin.myperfit.com/subscribe/entelequia/Ki1VK9NH' className="btn" target='_blank' rel="noreferrer">
                    Registrate acá
                </a>
            </Modal.Body>
        </Modal>
    );
};

export default NewsletterModal;